/// <reference path="../types.d.ts"/>






import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n, {t} from './i18n'
import {store} from './store/store'

import './plugins/fontawesome'
import './plugins/axios'
import './plugins/units'
import './plugins/mikrotik'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'animate.css/animate.min.css';

import './css/main.css';

import FastClick from 'vue-fastclick';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import './plugins/multipolyline.js'
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';

import PrimeVue from 'primevue/config';
import vmodal from 'vue-js-modal'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputTextArea from 'primevue/textarea';
import Rating from 'primevue/rating';
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import ComponentMessage from '@/components/ComponentMessage.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import NumberInput from '@/components/inputs/NumberInput.vue'
import DecimalInput from '@/components/inputs/DecimalInput.vue'
import EmailInput from '@/components/inputs/EmailInput.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import Alert from '@/components/Alert.vue'
import {ROUTE_PLAN_CALCULATOR} from "@/plugins/utils";
import {detect} from 'detect-browser';
import { PopoverPlugin, VBTooltipPlugin } from 'bootstrap-vue'
import { v4 } from "uuid";
import _ from "lodash";
import Checkbox from "primevue/checkbox";
import ConfirmModal from "@/components/ConfirmModal.vue";

window.allTiles = [
	'hu',
	'ro',
	'at',
	'da',
	'be',
	'bg',
	'ch',
	'cz',
	'de',
	'dk',
	'fi',
	'gr',
	'hr',
	'it',
	'nl',
	'pl',
	'se',
	'si',
	'sk',
]


window.consentedStorage = {

	setItem: function (key, value, consentKey = 'functionality_storage') {
		if (!window.localStorage) {
			return;
		}
		if(!window.consentMode) {
			return;
		}
		if(!window.consentMode[consentKey]) {
			return;
		}
		window.localStorage.setItem(key, value);
	},

	getItem: function (key, defaultValue = null) {
		if (!window.localStorage) {
			return defaultValue;
		}
		return window.localStorage.getItem(key) || defaultValue;
	},

	removeItem(key) {
		if (!window.localStorage) {
			return;
		}
		window.localStorage.removeItem(key);
	},

	clear() {
		if (!window.localStorage) {
			return;
		}
		let deviceId = window.localStorage.getItem('deviceId');
		window.localStorage.clear();
		window.localStorage.setItem('deviceId', deviceId);
	}
}
window.cookies = {
	set(key,value, days = 365) {
		let expires = "";
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = key + "=" + (value || "")  + expires + "; path=/";
	},
	get(key) {
		let keyEQ = key + "=";
		const ca = document.cookie.split(';');
		for(let i=0;i < ca.length;i++) {
			let c = ca[i];
			while (c.charAt(0)===' ') c = c.substring(1,c.length);
			if (c.indexOf(keyEQ) === 0) return c.substring(keyEQ.length,c.length);
		}
		return null;
	},
	remove(key) {
		document.cookie = key +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
}


FastClick.attach(document.body);
Vue.use(VBTooltipPlugin)

Vue.use(PrimeVue);
Vue.use(VBTooltipPlugin)
Vue.use(PopoverPlugin)
Vue.use(vmodal, {
  dynamicDefaults: {
    height: 'auto'
  }
});

Vue.use({
	install(Vue) {
		Vue.prototype.$confirm = async function (message, title = t('confirm.title'), options = {}) {
			return new Promise((resolve, reject) => {
				this.$modal.show(ConfirmModal, {
					title: title,
					message: message,
					...options
				}, {
					maxWidth: 400,
					height: 'auto',
					classes: 'confirm-modal',
					clickToClose: true,
					adaptive: true,
				}, {
					'before-close': (e) => {
						resolve(e.params);
					}
				});
			});
		}
	}
})


Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('InputText', InputText);
Vue.component('Slider', Slider);
Vue.component('InputNumber', InputNumber);
Vue.component('Calendar', Calendar);

Vue.config.productionTip = false

Vue.component('ComponentMessage', ComponentMessage);

Vue.component('TextInput', TextInput);
Vue.component('Rating', Rating);

Vue.component('InputTextArea', InputTextArea);

Vue.component('NumberInput', NumberInput);

Vue.component('DecimalInput', DecimalInput);

Vue.component('EmailInput', EmailInput);

Vue.component('PhoneInput', PhoneInput);

Vue.component('PasswordInput', PasswordInput);

Vue.component('Alert', Alert);

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


window.getParameterByName = getParameterByName;
window.platform = "web";
window.noGPSinDevice = false;

if (window.location.href.includes('file:///')) {
  let platform = getParameterByName('platform');
  if ("ios" === platform || "android" === platform) {
    window.platform = platform;
  }
  if (getParameterByName('noGPSinDevice')) {
		window.noGPSinDevice = true;
	}

	const tilesRaw = getParameterByName('tiles');
	const tiles = tilesRaw ? tilesRaw.split(';') : ['hu'];
	window.localStorage.setItem('tiles', JSON.stringify(tiles));
}

if(window.platform !== "web" && localStorage.getItem("consentMode") == null) {
	window.consentMode = {
		'ad_storage': false,
		'analytics_storage': true,
		'personalization_storage': true,
		'functionality_storage': true,
		'security_storage': true,
	}

	window.consentedStorage.setItem('consentMode', JSON.stringify(window.consentMode));

	window.gtag('consent', 'update', _.mapValues(window.consentMode, (value) => {
		return value ? 'granted' : 'denied';
	}));
}

window.version = null;
const version = getParameterByName('version');
if(version) {
  window.version = version;
}

window.canOfflineStart = getParameterByName('canOfflineStart') === '1';

try{
	window.inIframe = window.self !== window.top;
}catch(e){
	window.inIframe = true;
}


const detected = detect();
window.browser = detected ? detected.name : 'unknown';


if(window.consentedStorage.getItem("deviceId") == null) {
	window.consentedStorage.setItem("deviceId", v4());
}
window.deviceId = window.consentedStorage.getItem("deviceId");
window.osName = detected ? detected.os : 'unknown';
window.deviceName = detected ? detected.name : 'unknown';
window.deviceBrand = "unknown";


window.range = function (from, to) {
	let range = []
	for (let i = from; i < to; i++) {
		range.push(i);
	}
	return range;
}


window.loadAppLink = function (link) {
	let iframe = document.createElement('iframe');
	iframe.setAttribute('src', link);
	document.documentElement.appendChild(iframe);
	iframe.parentNode.removeChild(iframe);
}
var numeral = require("numeral"); // needs to var!
var moment = require("moment"); // needs to var!


/**
 * @param {string} value
 * @param {boolean} withYear
 * @returns {string}
 */
window.formatDate = function(value, withYear = true) {
	if(!value) return '';
	if(withYear) {
		return moment(value).utc().format("YYYY.MM.DD") + ' UTC';
	}
	return moment(value).utc().format("MM.DD") + ' UTC';
}

window.date = function(value, format) {
	if(!value) return '';
	return moment(value).utc().format(format);
}

window.formatBytes = function(bytes,decimals) {
	if(bytes === 0) return '0 Bytes';
	let k = 1024,
		dm = decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}



/**
 * @param {string} value
 * @param {boolean} withSeconds
 * @param {boolean} alreadyUTC
 * @param {string|null} customFormat
 * @returns {string}
 */
window.formatTime = function(value, withSeconds = true, alreadyUTC = false, customFormat = null) {
	if(!value) return '';
	let date;
	if(customFormat) {
		date = moment(value, customFormat);
	}
	else {
		date = moment(value);
	}

	if(alreadyUTC) {
		if(withSeconds) {
			return date.format("HH:mm:ss") + ' UTC';
		}
		return date.format("HH:mm") + ' UTC';
	}

	if(withSeconds) {
		return date.utc().format("HH:mm:ss") + ' UTC';
	}
	return date.utc().format("HH:mm") + ' UTC';
}


/**
 * @param {string} value
 * @param {boolean} withSeconds
 * @returns {string}
 */
window.formatDateTime = function(value, withSeconds = false) {
	if(!value) return '';
	if(withSeconds) {
		return moment(value).utc().format("YYYY.MM.DD HH:mm:ss") + ' UTC';
	}
	return moment(value).utc().format("YYYY.MM.DD HH:mm") + ' UTC';
}
Vue.filter("formatDate", window.formatDate);
Vue.filter("formatTime", window.formatTime);
Vue.filter("formatDateTime", window.formatDateTime);
Vue.filter("date", window.date);
Vue.filter("formatBytes", window.formatBytes);

Vue.filter("formatNumber", function (value, format = "0.00") {
  return numeral(value).format(format);
});
Vue.filter("formatCoordinates", function (value) {
  return numeral(value).format("0.000000");
});
Vue.filter("formatTrack", function (value) {
  if (value < 0) {
    return String(Math.ceil(360 + value)).padStart(3, '0');
  }
  return String(Math.ceil(value)).padStart(3, '0');
});

Vue.filter("cruisingSpeed", function (value) {
  return String(value).padStart(4, '0');
});
Vue.filter("flightLevel", function (value, unit = 'A') {
  if (unit === 'A') {
    return String(value).substring(0, (value.length - 2)).padStart(3, '0').substring(0, 3);
  }
  if (unit === 'M') {
    return String(value).substring(0, (value.length - 1)).padStart(4, '0').substring(0, 4);
  }
  if (unit === 'VFR') {
    return '';
  }
  return String(value).substring(0, (value.length - 2)).padStart(3, '0').substring(0, 3);
});
Vue.filter("formatHour", function (value) {
  const hourInMinutes = value * 60;
  const min = Math.ceil(hourInMinutes % 60);
  const hour = parseInt(value);
  if (hour > 0) {
    return String(hour).padStart(2, '0') + ' ' + t("routePlan.hour") + ' ' + String(min).padStart(2, '0') + ' ' + t("routePlan.minute");
  }
  return String(min).padStart(2, '0') + ' ' + t("routePlan.minute");
});


Vue.filter("formatHourWithoutUnit", function (value) {
  const hourInMinutes = value * 60;
  const min = Math.ceil(hourInMinutes % 60);
  const hour = parseInt(value);
  return String(hour).padStart(2, '0') + ':' + String(min).padStart(2, '0');
});

Vue.filter("formatLATDMS", function (value) {
  const hourInMinutes = value * 60;
  const min = Math.ceil(hourInMinutes % 60);
  const hour = parseInt(value);
  if (hour > 0) {
    return hour + ', ' + min + '';
  }
  return min;
});
Vue.filter("formatDMS", function (value, latLng = 'lat') {
  if (latLng === 'lat') {
    return ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LAT(value);
  }
  return ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LNG(value);
});

const rightMenu = Vue.observable({rightMenu: []});
Object.defineProperty(Vue.prototype, '$rightMenu', {
  get() {
    return rightMenu.rightMenu;
  },
  set(value) {
    rightMenu.rightMenu = value;
  }
});


String.prototype.fullNormalize = function() {
	return this
		.toLowerCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
}


new Vue({
  store: store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
