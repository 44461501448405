/// <reference path="../../types.d.ts"/>
"use strict";

import Vue from "vue";
import axios from "axios";
import "url-search-params-polyfill";
import { parsePlane } from "@/plugins/planeUtils";
import { store } from "@/store/store";
import versionConfig from "../../version.config";
import i18n from "@/i18n";
import { LOCAL_STORAGE_SERVICE, READ_JSON_FILE, STORAGE_KEY } from "@/plugins/utils";
import briefingmanager from "@/plugins/briefingmanager";

/** @type string */
let baseUrl;
let updatesUrl;

if (window.location.href.includes('file://') && !window.location.href.includes('testMode')) {
	baseUrl = 'https://api.pilotnet.hu/rest';
}
else if (window.location.href.indexOf('http://localhost') === 0) {
	// baseUrl = 'http://localhost:8080/rest';
	baseUrl = 'http://localhost:8080/rest';
	updatesUrl = "https://teszt.pilotnet.hu/updates";
} else if (window.location.hostname.includes('teszt') || window.location.href.includes('testMode')) {
	baseUrl = 'https://teszt.pilotnet.hu/rest';
	updatesUrl = "https://teszt.pilotnet.hu/updates";
} else {
	baseUrl = 'https://api.pilotnet.hu/rest';
	updatesUrl = "https://api.pilotnet.hu/updates";
}
// baseUrl = 'http://localhost:8080/rest';
axios.defaults.baseURL = baseUrl;




let config = {
	// baseURL: process.env.baseURL || process.env.apiUrl || ''
	timeout: 5 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);


const _rest = {
	baseUrl,
	updatesUrl
};


// **************************************************************************
// USER
// **************************************************************************

/**
 * Registers a new user with the given data
 * @param {({
 *   email: string,
 *   name: string,
 *   username: string,
 *   phone: string,
 *   password1: string,
 *   password2: string,
 *   accepted: boolean,
 *   avatar: string,
 *   avatarColor: string,
 * })} data
 * @returns {Promise<boolean>}
 */
_rest.register = async function (data) {
	let bodyFormData = new URLSearchParams();
	for(let key in data) {
		if(data[key]) {
			bodyFormData.append(key, data[key]);
		}
	}

	bodyFormData.forEach((v, k) => console.log(k, v))
	const resp = await postGeneralResult('user/register', bodyFormData);
	return resp.result;
}

/**
 * Login
 * @param {string} username
 * @param {string} password
 * @param {string} oauthId
 * @returns {Promise<GeneralResult<UserToken>>}
 */
_rest.login = async function (username, password, oauthId) {
	let bodyFormData = new URLSearchParams();
	bodyFormData.append('username', username);
	bodyFormData.append('password', password);
	bodyFormData.append('oauth_id', oauthId);

	return await postGeneralResult('user/login', bodyFormData);
}


/**
 * @param {string} username
 * @param {string} oauthId
 * @returns {Promise<GeneralResult<UserToken>>}
 */
_rest.oauthLogin = async function (username, oauthId) {
	let bodyFormData = new URLSearchParams();
	bodyFormData.append('username', username);
	bodyFormData.append('oauth_id', oauthId);

	/** @type {GeneralResult<UserToken|null>} */
	const resp = await postGeneralResult('user/oauthlogin', bodyFormData);
	return resp;
}


/**
 * Gets the logged-in user
 * @returns {Promise<User|null>}
 */
_rest.getLoggedInUser = async function () {
	let loggedInEmail = store.getters.loggedInEmail;
	let loggedInUserToken = store.getters.loggedInUserToken;

	if(loggedInEmail && loggedInUserToken) {
		/** @type {GeneralResult<User|null>} */
		const resp = await getGeneralResult('user/loggedin-user');
		return resp.data;
	}
	return null;
};

/**
 * Deletes the logged-in user profile
 * @returns {Promise<boolean>}
 */
_rest.deleteProfile = async function () {
	/** @type {GeneralResult<boolean>} */
	const resp = await deleteGeneralResult('user/delete');
	return resp.result;
}


/**
 * Login with Google
 * @param {string} token
 * @returns {Promise<UserToken|null>}
 */
_rest.googleLogin = async  function (token) {
	const formData = new URLSearchParams()
	formData.append("idToken", token)

	/** @type {GeneralResult<UserToken|null>} */
	const resp = await postGeneralResult('user/googleLogin', formData);
	return resp.data;
}

/**
 * Login with Apple
 * @param {({
 *   userId: string,
 *   name: string,
 *   givenName: string,
 *   familyName: string,
 *   email: string,
 * })} data
 * @returns {Promise<UserToken|null>}
 */
_rest.appleLogin = async function (data) {
	const formData = new URLSearchParams()
	formData.append("user", data.userId)
	formData.append("name", data.name)
	formData.append("given_name", data.givenName)
	formData.append("family_name", data.familyName)
	formData.append("email", data.email)

	const resp = await postGeneralResult('user/appleLogin', formData);
	return resp.data;
}

/**
 * Logout
 * @param {boolean} background
 * @returns {Promise<boolean>}
 */
_rest.logout = async function (background = false) {
	const resp = await getGeneralResult('user/logout', { background });
	return resp.result;
};

/**
 * Sends the forgotten password email
 * @param {string} email
 * @returns {Promise<boolean>}
 */
_rest.forgottenPasswordEmail = async function (email) {
	const bodyFormData = new URLSearchParams();
	if (email) {
		bodyFormData.append('email', email);
	}

	const resp = await postGeneralResult('user/forget-password', bodyFormData);
	return resp.result;
}

/**
 * Change the password after password forgotten
 * @param {string} password
 * @param {string} password2
 * @param {string} token
 * @returns {Promise<boolean>}
 */
_rest.forgottenPasswordChange = async function (password, password2, token) {
	const bodyFormData = new URLSearchParams();
	if (password) {
		bodyFormData.append('password', password);
	}
	if (password2) {
		bodyFormData.append('password2', password2);
	}
	if (token) {
		bodyFormData.append('token', token);
	}

	const resp = await postGeneralResult('user/forget-password-change', bodyFormData);
	return resp.result;
}

/**
 * Saves the profile
 * @param {({
 *   name: string,
 *   username: string,
 *   email: string,
 *   phone: string,
 *   password: string,
 *   password2: string,
 *   oldPassword: string,
 *   avatar: string,
 *   avatarColor: string,
 * })} data
 * @returns {Promise<boolean>}
 */
_rest.saveProfile =  async function (data) {
	const bodyFormData = new URLSearchParams();
	for(let key in data) {
		if(data[key]) {
			bodyFormData.append(key, data[key]);
		}
	}

	const resp = await putGeneralResult('user/update', bodyFormData);
	return resp.result;
}


/**
 * Creates a user event
 * @param {UserEventType} type
 * @param {Record<string, string>} [data = {}]
 * @param {String|null} [time = null]
 * @returns {Promise<boolean>}
 */
_rest.event =  async function (type, data = {}, time = null) {

	let body = {
		type: type,
		platform: window.platform,
		bundleVersion: String(versionConfig.bundleVersion),
		appVersion: window.appVersion,
		osName: window.osName,
		deviceType: window.platform.toUpperCase(),
		deviceId: window.deviceId,
		sessionId: window.sessionId,
		deviceName: window.deviceName,
		deviceBrand: window.deviceBrand,
		language: i18n.locale,
		fcmToken: window.consentedStorage.getItem('fcmToken'),
		iframe: window.inIframe,
		referrer: document.referrer,
		data: JSON.stringify(data),
		time: time,
	}

	const bodyFormData = new URLSearchParams();

	for(let key in body) {
		if(body[key]) {
			bodyFormData.append(key, body[key]);
		}
	}

	const resp = await postGeneralResult('user/event', bodyFormData, { background: true });
	return resp.result;
}



/**
 * Unsuscribe from email notifications
 * @param {string} token
 * @param {string[]} types
 * @returns {Promise<boolean>}
 */
_rest.unsubscribe = async function (token, types) {
	const bodyFormData = new URLSearchParams();
	if (types) {
		bodyFormData.append('types', types.join(';'));
  }
	if (token) {
		bodyFormData.append('token', token);
	}

	const resp = await postGeneralResult('user/unsubscribe', bodyFormData);
	return resp.result;
}


/**
 * Set password for the first time
 * @param {string} token
 * @param {string} pass1
 * @param {string} pass2
 * @returns {Promise<UserToken|null>}
 */
_rest.setPassword = async function (token, pass1, pass2) {
	const bodyFormData = new URLSearchParams();
	bodyFormData.append('password1', pass1);
	bodyFormData.append('password2', pass2);

	const resp = await postGeneralResult(`user/set-password?token=${token}`, bodyFormData);
	console.log({ resp });
	return resp.data;
}


// **************************************************************************
// BRIEFING
// **************************************************************************
/**
 * Generates a briefing pdf
 * @param {number} routePlanId
 * @param {number} flightPlanId
 * @returns {Promise<string>}
 * */
_rest.generateBriefing = async function (routePlanId, flightPlanId) {

	let params = new URLSearchParams();
	if(routePlanId) {
		params.append('routePlanId', String(routePlanId));
	}
	if(flightPlanId) {
		params.append('flightPlanId', String(flightPlanId));
	}

	const airspaceTypes = JSON.parse(localStorage.getItem("airspaceTypeData") || "{}");
	if(airspaceTypes) {
		params.append('airspaceTypes', Object.keys(airspaceTypes).filter(k => airspaceTypes[k]).join(';'));
	}

	const maxAlt = localStorage.getItem('maxElevFilter');
	if(maxAlt) {
		params.append('maxAlt', maxAlt);
	}

	params.append("airports", localStorage.getItem("toggleAirports") === '1' ? 'true' : 'false')
	params.append("navpoints", localStorage.getItem("toggleNavPoints") === '1' ? 'true' : 'false')

	const briefing = briefingmanager.getGroups();

	/** @type {GeneralResult<string>} */
	const result = await postGeneralResult(`briefing/pdf?${params.toString()}`, briefing, {
		timeout: 1000 * 60 * 5
	});
	return result.data;
}

/**
 * Gets the briefing pdf url
 * @param {string} name
 * @returns {string}
 * */
_rest.getBriefingPdfUrl = function (name) {
	return `${baseUrl}/briefing/pdf/${name}`;
}


// **************************************************************************
// PLANES
// **************************************************************************
/**
 * Attaches the logged-in user to the given plane
 * @param {number} planeId
 * @returns {Promise<boolean>}
 * */
_rest.attachToPlane = async function (planeId) {
	/** @type {GeneralResult<null>} */
	const result = await postGeneralResult(`plane/${planeId}/attach`, {});
	return result.result;
}

/**
 * Detaches the logged-in user from the given plane
 * @param {number} planeId
 * @returns {Promise<boolean>}
 */
_rest.detachFromPlane = async function (planeId) {
	/** @type {GeneralResult<null>} */
	const result = await deleteGeneralResult(`plane/${planeId}`);
	return result.result;
}

/**
 * Gets the user planes of the logged-in user
 * @returns {Promise<UserPlane[]|null>}
 */
_rest.loadMyUserPlanes = async function () {
	/** @type {GeneralResult<UserPlane[]|null>} */
	const result = await getGeneralResult('plane/my-planes');
	if(!result.result) return null;
	return (result.data || []).map(up => ({
		...up,
		plane: parsePlane(up.plane)
	}));
}

/**
 * Gets all the planes in the system without the user's planes
 * @returns {Promise<Plane[]|null>}
 */
_rest.loadAllPlanes = async function () {
	/** @type {GeneralResult<Plane[]|null>} */
	const result = await getGeneralResult('plane');
	if(!result.result) return null;
	return (result.data || []).map(parsePlane);
}

/**
 * Creates a new plane
 * @param {Plane} plane
 * @returns {Promise<Plane|null>}
 */
_rest.addNewPlane = async function (plane) {
	/** @type {GeneralResult<Plane|null>} */
	const result = await postGeneralResult('plane', plane);
	if(!result.data) return null;
	return parsePlane(result.data);
}

/**
 * Modifies a plane
 * @param{Plane} plane
 * @returns {Promise<boolean>}
 */
_rest.modifyPlane = async function (plane) {
	/** @type {GeneralResult<null>} */
	const result = await patchGeneralResult(`plane/${plane.id}`, plane);
	return result.result;
}

/**
 * Get a user plane by id
 * @param {number} planeId
 * @returns {Promise<UserPlane|null>}
 */
_rest.loadUserPlane = async function (planeId) {
	/** @type {GeneralResult<UserPlane|null>} */
	const result = await getGeneralResult(`plane/${planeId}/my`);
	if(!result.data) return null;
	return {
		...result.data,
		plane: parsePlane(result.data.plane)
	}
}

/**
 * Get a plane details by id
 * @param {number} planeId
 * @returns {Promise<Plane|null>}
 */
_rest.loadPlane = async function (planeId) {
	/** @type {GeneralResult<Plane|null>} */
	const result = await getGeneralResult(`plane/${planeId}`);
	if(!result.data) return null;
	return parsePlane(result.data);
}

/**
 * Get plane changes by id
 * @param {number} planeId
 * @param planeId
 * @returns {Promise<PlaneChange[]>}
 */
_rest.loadUserPlaneChanges = async function (planeId) {
	/** @type {GeneralResult<PlaneChange[]>} */
	const result = await getGeneralResult(`plane/${planeId}/changes`);
	return result.data || [];
}

/**
 * Gets all the plane changes to be approved by the logged-in user
 * @returns {Promise<PlaneChange[]>}
 */
_rest.loadAllPlaneChanges = async function () {
	/** @type {GeneralResult<PlaneChange[]|null>} */
	const result = await getGeneralResult('plane/changes');
	return result.data || [];
}

/**
 * Approves or rejects a plane change
 * @param {number} id
 * @param {boolean} approved
 * @param {string} comment
 * @returns {Promise<boolean>}
 */
_rest.approvePlaneChange = async function (id, approved, comment) {
	let data = new URLSearchParams();
	data.append('approve', approved);
	if(comment) data.append('comment', comment);

	/** @type {GeneralResult<null>} */
	const result = await postGeneralResult(`plane/approve/${id}`, data);
	return result.result;
}

/**
 * Saves the cog values of a plane
 * @param {number} planeId
 * @param {CogValue[]} cogValues
 * @returns {Promise<boolean>}
 */
_rest.saveCogValues = async function (planeId, cogValues) {
	/** @type {GeneralResult<null>} */
	const result = await postGeneralResult(`plane/${planeId}/cogValues`, cogValues);
	return result.result;
}

/**
 * Loads the cog values of a plane
 * @param {number} planeId
 * @returns {Promise<CogValue[]>}
 */
_rest.loadCogValues = async function (planeId) {
	/** @type {GeneralResult<CogValue[]>} */
	const result = await getGeneralResult(`plane/${planeId}/cogValues`);
	return result.data || [];
}

/**
 * Get a user plane by id
 * @param {string} query
 * @returns {Promise<User[]|null>}
 */
_rest.getOperators = async function (query) {
	/** @type {GeneralResult<UserPlane|null>} */
	const result = await getGeneralResult(`plane/operators?query=${query}`);
	if(!result.data) return null;
	return result.data
}


/**
 * Verify a plane
 * @param {string} token
 * @returns {Promise<boolean>}
 */
_rest.verifyPlane = async function (token) {
	const result = await postGeneralResult(`plane/verify/${token}`, {});
	return result.result
}

/**
 * Deny a plane
 * @param {string} token
 * @returns {Promise<boolean>}
 */
_rest.denyPlane = async function (token) {
	const result = await postGeneralResult(`plane/deny/${token}`, {});
	return result.result
}


//****************************************************************************************************
// SHARING
//****************************************************************************************************

/**
 * Shares a thing with users
 * @param {Sharable} sharable
 * @param {string} id
 * @param {string[]} emails
 * @param {number[]} userIds
 * @returns {Promise<boolean>}
 */
_rest.share = async function (sharable, id, emails, userIds) {
	let bodyFormData = new URLSearchParams();
	if (emails) {
		bodyFormData.append('emails', emails.join(';'));
	}
	if (userIds) {
		bodyFormData.append('userIds', userIds.join(';'));
	}

	const resp = await postGeneralResult(`share/${sharable}/${id}`, bodyFormData);
	return resp.result;
}

/**
 * Search for users
 * @param {Sharable} sharable
 * @param {string} id
 * @param {string} query
 * @returns {Promise<User[]>}
 */
_rest.shareSearch = async function (sharable, id, query) {
	const resp = await getGeneralResult(`share/${sharable}/${id}/search?query=${query}`);
	if(!resp.result) return [];
	return resp.data || [];
}

//****************************************************************************************************
// ROUTES
//****************************************************************************************************

/**
 * Exports a route to gpx format
 * @param routeId
 * @returns {Promise<Blob|null>}
 */
_rest.exportRoute = async function (routeId) {
	const resp = await _axios.get(`/route/${routeId}/export`, {
		responseType: 'blob',
	}).catch(handleCatch);
	if(!resp) return null;
	if(!resp.data) return null;
	return new Blob([resp.data], {type: 'application/gpx+xml'});
};

/**
 * Hide or unhide a route
 * @param {number} routeId
 * @param {boolean} hidden
 * @returns {Promise<Route|null>}
 */
_rest.toggleHiddenRoute = async function (routeId, hidden) {
	const resp = await postGeneralResult(`/route/${routeId}/hidden/${hidden ? 'true' : 'false'}`, {});
	if(!resp.result) return null;
	return resp.data;
};

/**
 * Gets all the routes of the logged-in user
 * @param {number} page
 * @param {number} pageSize
 * @param {Record<string, string>} filters
 * @returns {Promise<Route[]|null>}
 */
_rest.getRoutes = async function (page, pageSize, filters) {
	const url = new URLSearchParams();
	url.append('page', page);
	url.append('pageSize', pageSize);
	for(let key in filters) {
		if(filters[key]) {
			url.append(key, filters[key]);
		}
	}

	const resp = await getGeneralResult(`route?${url.toString()}`);
	if(!resp.result) return null;
	return resp.data;
};

/**
 * Gets the route by id
 * @param {number} id
 * @returns {Promise<Route>}
 */
_rest.getRoute = async function (id) {
	const resp = await getGeneralResult(`route/${id}`);
	if(!resp.result) return null;
	return resp.data;
};


/**
 * Gets the coordinates of a route
 * @param {number} routeId
 * @param {number} page
 * @param {number} pageSize
 * @returns {Promise<CoordinatesResponse|null>}
 */
_rest.getCoordinates = async function (routeId, page, pageSize) {
	const resp = await getGeneralResult(`route/${routeId}/coordinates?page=${page}&pageSize=${pageSize}`, {
		timeout: 15000
	});
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Starts a route
 * @param {({
 *   registrationNumber: string,
 *   routePlanId: number,
 *   beaconType: BeaconType,
 *   fcmToken: string
 * })} data
 * @returns {Promise<string|null>}
 */
_rest.startRoute = async function (data) {
	const bodyFormData = new URLSearchParams();
	for(let key in data) {
		if(data[key]) {
			bodyFormData.append(key, data[key]);
		}
	}
	const resp = await putGeneralResult('route/start', bodyFormData);
	if(!resp.result) return null;
	return resp.data;
};

/**
 * Stops a route
 * @param {string} routeId
 * @returns {Promise<boolean>}
 */
_rest.stopRoute = async function (routeId) {
	const bodyFormData = new URLSearchParams();
	if (routeId) {
		bodyFormData.append('routeId', routeId);
	}

	const resp = await postGeneralResult('route/stop', bodyFormData);
	return resp.result;
}

/**
 * Replaces the route changes of a route
 * @param {number} routeId
 * @param {RoutePlanChange[]} routeChanges
 * @returns {Promise<Route|null>}
 */
_rest.replaceRouteChanges = async function (routeId, routeChanges) {
	const resp = await patchGeneralResult(`route/${routeId}/changes`, routeChanges);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Appends route changes to a route
 * @param {number} routeId
 * @param {RoutePlanChange[]} routeChanges
 * @returns {Promise<Route|null>}
 */
_rest.appendRouteChanges = async function (routeId, routeChanges) {
	const resp = await postGeneralResult(`route/${routeId}/changes`, routeChanges);
	if(!resp.result) return null;
	return resp.data;
}

//****************************************************************************************************
// FLYING OBJECTS
//****************************************************************************************************
/**
 * Gets the coordinates of a flying object
 * @param {string} registrationNumber
 * @returns {Promise<CoordinatesResponse>}
 */
_rest.getCoordinatesOfFlyingObject = async function (registrationNumber) {
	const resp = await getGeneralResult(`flyingobjects/${registrationNumber}/coordinates`);
	if(!resp.result) return null;
	return resp.data;
}


//****************************************************************************************************
// AIRPORTS
//****************************************************************************************************

/**
 * Gets all the airports (also caches them in local storage and returns them if no internet connection)
 * @returns {Promise<Airport[]>}
 */
_rest.getAirports = async function () {
	const resp = await getGeneralResult('airport/all');
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('airports') || "[]");
	const airports =  resp.data || JSON.parse(window.consentedStorage.getItem('airports') || "[]");
	window.consentedStorage.setItem('airports', JSON.stringify(airports));
	return airports;
};

/**
 * Gets an airport by id
 * @param {number} airportId
 * @returns {Promise<Airport>}
 */
_rest.getAirport = async function (airportId) {
	const resp = await getGeneralResult(`airport/${airportId}`);
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('airports') || "[]").find(n => n.id === airportId);
	return resp.data || JSON.parse(window.consentedStorage.getItem('airports') || "[]").find(n => n.id === airportId);
};


//****************************************************************************************************
// NAVPOINTS
//****************************************************************************************************


/**
 * Gets all the navpoints (also caches them in local storage and returns them if no internet connection)
 * @returns {Promise<NavPoint[]>}
 */
_rest.getNavPoints = async function () {
	const resp = await getGeneralResult('navpoint/all');
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('navpoints') || "[]");
	const navpoints = resp.data || JSON.parse(window.consentedStorage.getItem('navpoints') || "[]");
	window.consentedStorage.setItem('navpoints', JSON.stringify(navpoints));
	return navpoints;
};

/**
 * Gets a navpoint by id
 * @param {number} navpointId
 * @returns {Promise<NavPoint|null>}
 */
_rest.getNavPoint = async function (navpointId) {
	const resp = await getGeneralResult(`navpoint/${navpointId}`);
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('navpoints') || "[]").find(n => n.id === navpointId);
	return resp.data || JSON.parse(window.consentedStorage.getItem('navpoints') || "[]").find(n => n.id === navpointId);
}



//****************************************************************************************************
// NOTAMS
//****************************************************************************************************

/**
 * Gets all the notams (also caches them in local storage and returns them if no internet connection)
 * @returns {Promise<Notam[]>}
 */
_rest.getNotams = async function () {
	const resp = await getGeneralResult('notam/all');
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('notams') || "[]");
	const notams = resp.data || JSON.parse(window.consentedStorage.getItem('notams') || "[]");
	window.consentedStorage.setItem('notams', JSON.stringify(notams));
	return notams;
}

/**
 * Gets a notam by id (also caches it in local storage and returns it if no internet connection)
 * @param {number} notamId
 * @returns {Promise<Notam|null>}
 */
_rest.getNotam = async function (notamId) {
	const resp = await getGeneralResult(`notam/${notamId}`);
	if(!resp.result) return JSON.parse(window.consentedStorage.getItem('notams') || "[]").find(n => n.id === notamId);
	return resp.data || JSON.parse(window.consentedStorage.getItem('notams') || "[]").find(n => n.id === notamId);
}


//****************************************************************************************************
// WEATHER
//****************************************************************************************************


/**
 * Gets the weather for a given ICAO code
 * @param {string} icao
 * @returns {Promise<Weather|null>}
 */
_rest.getWeatherWithICAO = async function (icao) {
	const resp = await getGeneralResult(`weather/${icao}`);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Gets the weather for a given coordinates
 * @param {number} lat
 * @param {number} lng
 * @returns {Promise<Weather|null>}
 */
_rest.getWeatherWithCoordinates = async function (lat, lng) {
	const resp = await getGeneralResult(`weather/${lat}/${lng}`);
	if(!resp.result) return null;
	return resp.data;
}



//****************************************************************************************************
// SERVICES
//****************************************************************************************************

/**
 * Gets the url of the websocket
 * @returns {Promise<string>}
 */
_rest.getWebSocketUrl = async function () {
	const resp = await getGeneralResult(`/service/ws-url`);
	if(!resp.result) return 'wss://flight.r2g.hu/rest/update';
	return resp.data;
}

//****************************************************************************************************
// AIRSPACES
//****************************************************************************************************

/**
 * Gets all temp airspaces
 * @returns {Promise<TempAirspace[]>}
 */
_rest.getTempAirspaces = async function () {
	const resp = await getGeneralResult(`/temp-airspace`)
	if(!resp.result) return [];
	return resp.data || [];
}

/**
 * Gets all airspaces
 * @returns {Promise<Airspace[]>}
 */
_rest.getAirspaces = async function () {
	const resp = await getGeneralResult(`/airspace?updatedAt=${(new Date().getTime() / 1000).toFixed(0)}`);
	if(!resp.result) return [];
	return resp.data || [];
}


//****************************************************************************************************
// FLIGHT PLANS
//****************************************************************************************************

/**
 * Gets all flight plans for the current user
 * @returns {Promise<FlightPlan[]>}
 */
_rest.getFlightPlansForUser = async function () {
	const resp = await getGeneralResult(`/flight-plan`);
	if(!resp.result) return [];
	return resp.data || [];
}

/**
 * Creates a new flight plan
 * @param {FlightPlan} plan
 * @returns {Promise<FlightPlan|null>}
 */
_rest.addFlightPlan = async function (plan) {
	const resp = await postGeneralResult(`/flight-plan`, plan);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Updates a flight plan
 * @param {FlightPlan} plan
 * @returns {Promise<FlightPlan|null>}
 */
_rest.updateFlightPlan = async function (plan) {
	const resp = await putGeneralResult(`/flight-plan`, plan);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Deletes a flight plan
 * @param {number} id
 * @returns {Promise<boolean>}
 */
_rest.deleteFlightPlan = async function (id) {
	const resp = await deleteGeneralResult(`/flight-plan/${id}`);
	return resp.result;
}

//****************************************************************************************************
// ROUTE PLAN
//****************************************************************************************************

/**
 * Gets all route plans for the current user
 * @returns {Promise<RoutePlan[]|null>}
 */
_rest.getRoutePlansForUser = async function () {
	const resp = await getGeneralResult(`/route-plan`);
	if(!resp.result) return null;
	return resp.data;
}


/**
 * Gets a route plan by id
 * @param {number} id
 * @returns {Promise<RoutePlan|null>}
 */
_rest.getRoutePlanById = async function (id) {
	const resp = await getGeneralResult(`/route-plan/${id}`);
	if(!resp.result) return null;
	return resp.data;
}


/**
 * Gets cities in a circle
 * @param {number} lat
 * @param {number} lng
 * @param {number} distance
 * @returns {Promise<NavObjectPayload[]>}
 */
_rest.getCitiesInACircle = async function (lat, lng, distance) {
	const resp = await getGeneralResult(`/route-plan/objects-in-circle?lat=${lat}&lng=${lng}&distance=${distance}`);
	if(!resp.result) return [];
	return resp.data || [];
}

/**
 * Gets all nav objects (airports and cities)
 * @returns {Promise<(Airport|City)[]>}
 */
_rest.getAllObjects = async function () {
	let objects = JSON.parse(window.consentedStorage.getItem(STORAGE_KEY.NAV_OBJECT, "[]"));
	if(objects.length !== 0 && objects instanceof Array) {
		return objects;
	}
	const resp = await getGeneralResult(`/route-plan/objects-all`, {
		background: true,
		timeout: 1000,
	});
	if(resp.result) {
		objects = resp.data || [];
	}else{
		objects = READ_JSON_FILE('objects.json')
	}
	window.consentedStorage.setItem(STORAGE_KEY.NAV_OBJECT, JSON.stringify(objects));
	return objects;

}

/**
 * Deletes a route plan
 * @param {number} id
 * @returns {Promise<boolean>}
 */
_rest.deleteRoutePlan = async function (id) {
	const resp = await deleteGeneralResult(`/route-plan/${id}`);
	return resp.result;
}

/**
 * Creates a new route plan
 * @param {RoutePlan} plan
 * @returns {Promise<RoutePlan|null>}
 */
_rest.addRoutePlan = async function (plan) {
	const resp = await postGeneralResult(`/route-plan`, plan);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Updates a route plan
 * @param {RoutePlan} plan
 * @returns {Promise<RoutePlan|null>}
 */
_rest.updateRoutePlan = async function (plan) {
	const resp = await putGeneralResult(`/route-plan`, plan);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Add route plan elements
 * @param {number} id
 * @param {RoutePlanElement[]} planElements
 * @returns {Promise<RoutePlan|null>}
 */
_rest.addRoutePlanElements = async function (id, planElements) {
	const resp = await postGeneralResult(`/route-plan/${id}/elements`, planElements);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Removes route plan elements
 * @param {number} id
 * @param {number[]} planElementIds
 * @returns {Promise<RoutePlan|null>}
 */
_rest.removeRoutePlanElements = async function (id, planElementIds) {
	const resp = await patchGeneralResult(`/route-plan/${id}/elements`, planElementIds);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Add route plan alternates
 * @param {number} id
 * @param {RoutePlanElement[]} planElements
 * @returns {Promise<RoutePlan|null>}
 */
_rest.addAlternates = async function (id, planElements) {
	const resp = await postGeneralResult(`/route-plan/${id}/alternates`, planElements);
	if(!resp.result) return null;
	return resp.data;
}

/**
 * Removes route plan alternates
 * @param {number} id
 * @param {number[]} planElementIds
 * @returns {Promise<RoutePlan|null>}
 */
_rest.removeAlternates = async function (id, planElementIds) {
	const resp = await patchGeneralResult(`/route-plan/${id}/alternates`, planElementIds);
	if(!resp.result) return null;
	return resp.data;
}

//****************************************************************************************************
// QUESTIONNAIRE
//****************************************************************************************************

/**
 * Gets all questionnaire answers for the current user
 * @returns {Promise<QuestionnaireAnswer[]>}
 */
_rest.getQuestionnaireAnswers = async function () {
	const resp = await getGeneralResult(`/questionnaire`)
	if(!resp.result) return [];
	return resp.data || [];
}

/**
 * Gets a questionnaire answer by token
 * @param {string} token
 * @returns {Promise<QuestionnaireAnswer|null>}
 */
_rest.getQuestionnaireAnswer = async function (token) {
	const resp = await getGeneralResult(`/questionnaire/${token}`)
	if(!resp.result) return null;
	return resp.data || null;
}

/**
 * Gets a questionnaire answer by token and question id
 * @param {string} token
 * @param {number} questionId
 * @returns {Promise<QuestionAnswer|null>}
 */
_rest.getQuestionAnswer = async function (token, questionId) {
	const resp = await getGeneralResult(`/questionnaire/${token}/${questionId}`)
	if(!resp.result) return null;
	return resp.data || null;
}

/**
 * Delete a question answer file by token and question id and file name
 * @param {string} token
 * @param {number} questionId
 * @param {string} fileName
 * @returns {Promise<QuestionAnswer|null>}
 */
_rest.deleteQuestionAnswerFile = async function (token, questionId, fileName) {
	const resp = await deleteGeneralResult(`/questionnaire/${token}/${questionId}/${fileName}`, {})
	if(!resp.result) return null;
	return resp.data || null;
}

/**
 * Saves a question answer
 * @param {string} token
 * @param {number} questionId
 * @param {QuestionAnswer} answer
 * @returns {Promise<QuestionAnswer|null>}
 */
_rest.saveQuestionAnswer = async function (token, questionId, answer) {
	const resp = await postGeneralResult(`/questionnaire/${token}/${questionId}`, answer)
	if(!resp.result) return null;
	return resp.data || null;
}

/**
 * Submit a questionnaire
 * @param {string} token
 * @param {string} comment
 * @returns {Promise<Record<string, string>|null>}
 */
_rest.submitQuestionnaireAnswer = async function (token, comment) {
	const resp = await postGeneralResult(`/questionnaire/${token}`, comment)
	if(!resp.result) return null;
	return resp.data || null;
}

//****************************************************************************************************
// BUNDLES
//****************************************************************************************************

/**
 * Get All Downloadable Bundles
 * @returns {Promise<Bundle[]|null>}
 */
_rest.getDownloadableBundles = async function () {
	const  resp = await axios.get(updatesUrl).catch(handleCatch);

	if(!resp) return null;
	if(!resp.data) return null;
	return Object.values(resp.data);
}

//****************************************************************************************************
// FILES
//****************************************************************************************************

/**
 * Get All My Files
 * @returns {Promise<VirtualFile[]|null>}
 */
_rest.getAllMyFiles = async function () {
	const  resp = await _axios.get("/file/all").catch(handleCatch);

	if(!resp) return null;
	if(!resp.data) return null;
	return Object.values(resp.data);
}


/**
 * Upload Files
 * @param {File[]} files
 * @param {string} [path]
 * @returns {Promise<boolean>}
 */
_rest.uploadFiles = async function (files, path = "/") {
	const formData = new FormData()
	files.forEach((file) => {
		formData.append("file", file, file.name);
	})
	const  resp = await postGeneralResult(`/file?path=${path}`, formData);
	return resp.result;
}



/**
 * Get My Files Info
 * @returns {Promise<MyFileInfo>}
 */
_rest.getMyFilesInfo = async function () {
	const  resp = await getGeneralResult(`/file/info`);
	return resp.data;
}

/**
 * Get File URL
 * @param {string} [path]
 * @returns {string}
 */
_rest.getFileUrl = function (path = "/") {
	return `${baseUrl}/file?path=${path}&token=${store.getters.loggedInUserToken || ''}`;
}

/**
 * Delete file
 * @param {string} path
 * @returns {Promise<boolean>}
 */
_rest.deleteFile = async function (path) {
	const  resp = await deleteGeneralResult(`/file?path=${path}`);
	return resp.result;
}

/**
 * Rename file
 * @param {string} path
 * @param {string} newName
 * @returns {Promise<boolean>}
 */
_rest.renameFile = async function (path, newName) {
	const  resp = await patchGeneralResult(`/file?path=${path}&newName=${newName}`, {});
	return resp.result;
}
//**********************************************************************************************************************

const RestPlugin = {
	install(Vue, options) {
		window.axios = _axios;
		window._rest = _rest;
		Vue.prototype.$axios = _axios;
		Vue.prototype.axios = _axios;
		Vue.prototype.$rest = _rest;
	}
}

Vue.use(RestPlugin)
if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(RestPlugin)
}
export default RestPlugin;
export const rest = _rest;








//Utility functions
/**
 * Logs the error and returns null (for catch)
 * @param {Error} e
 * @returns {null}
 */
function handleCatch(e) {
	console.error(e);
	return null;
}

const DEFAULT_GENERAL_RESULT = {
	result: false,
	data: null,
	messages: [],
}

/**
 * @param {string} url
 * @param {({
 *   background?: boolean,
 *   timeout?: number,
 * })} [settings]
 * @template T
 * @returns {Promise<GeneralResult<T>>}
 */
async function getGeneralResult(url, settings= {background: false}) {
	const resp = await _axios.get(url,{
		background: settings.background,
		timeout: settings.timeout,
	}).catch(handleCatch);
	if(!resp) return DEFAULT_GENERAL_RESULT;
	if(!resp.data) return DEFAULT_GENERAL_RESULT;
	return resp.data;
}


/**
 * @param {string} url
 * @param {*} data
 * @param {({
 *   background?: boolean,
 *   timeout?: number,
 * })} [settings]
 * @template T
 * @returns {Promise<GeneralResult<T>>}
 */
async function patchGeneralResult(url, data, settings= {background: false}) {
	const resp = await _axios.patch(url, data, {
		background: settings.background,
		timeout: settings.timeout,
	}).catch(handleCatch);
	if(!resp) return DEFAULT_GENERAL_RESULT;
	if(!resp.data) return DEFAULT_GENERAL_RESULT;
	return resp.data;
}

/**
 * @param {string} url
 * @param {*} data
 * @param {({
 *   background?: boolean,
 *   timeout?: number,
 * })} [settings]
 * @template T
 * @returns {Promise<GeneralResult<T>>}
 */
async function putGeneralResult(url, data, settings= {background: false}) {
	const resp = await _axios.put(url, data, {
		background: settings.background,
		timeout: settings.timeout,
	}).catch(handleCatch);
	if(!resp) return DEFAULT_GENERAL_RESULT;
	if(!resp.data) return DEFAULT_GENERAL_RESULT;
	return resp.data;
}



/**
 * @param {string} url
 * @param {*} data
 * @param {({
 *   background?: boolean,
 *   timeout?: number,
 * })} [settings]
 * @template T
 * @returns {Promise<GeneralResult<T>>}
 */
async function postGeneralResult(url, data, settings= {background: false}) {
	const resp = await _axios.post(
		url, data, {
		background: settings.background,
		timeout: settings.timeout,
	}).catch(handleCatch);
	if(!resp) return DEFAULT_GENERAL_RESULT;
	if(!resp.data) return DEFAULT_GENERAL_RESULT;
	return resp.data;
}


/**
 * @param {string} url
 * @param {Record<string, string>} params?
 * @template T
 * @returns {Promise<GeneralResult<T>>}
 */
async function deleteGeneralResult(url, params = {}) {
	const resp = await _axios.delete(url, {
		params: params
	}).catch(handleCatch);
	if(!resp) return DEFAULT_GENERAL_RESULT;
	if(!resp.data) return DEFAULT_GENERAL_RESULT;
	return resp.data;
}
